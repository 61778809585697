'use strict';

angular.module('managerApp').config(($routeProvider) => {
    $routeProvider.when('/clients', {
        templateUrl: 'app/clients/clients.html',
        controller: 'ClientsController',
        hasPermissions: ['CLIENTS:GET'], //, 'CLIENTS:POST', 'CLIENTS:PUT', 'CLIENTS:DELETE']
        pageTitle: 'Liste de clients '
    }).when('/clients/add', {
        templateUrl: 'app/clients/add.edit.html',
        controller: 'ClientsController',
        hasPermissions: ['CLIENTS:POST']
    }).when('/clients/:id/edit', {
        templateUrl: 'app/clients/add.edit.html',
        controller: 'ClientsController',
        hasPermissions: ['CLIENTS:GET', 'CLIENTS:PUT', 'CLIENTS:DELETE']
    }).when('/clients/partenaire', {
        templateUrl: 'app/clients/typeFilteredClients.html',
        controller: 'ClientsController',
        hasPermissions: ['CLIENTS:GET'], //, 'CLIENTS:POST', 'CLIENTS:PUT', 'CLIENTS:DELETE']
        pageTitle: 'Liste de partenaires '
    }).when('/clients/:id', {
        templateUrl: 'app/clients/client.html',
        controller: 'ClientsController',
        hasPermissions: ['CLIENTS:GET']
    }).when('/clients/partenaire/add', {
        templateUrl: 'app/clients/add.edit.typedClient.html',
        controller: 'ClientsController',
        hasPermissions: ['CLIENTS:POST']
    }).when('/clients/partenaire/:id/edit', {
        templateUrl: 'app/clients/add.edit.typedClient.html',
        controller: 'ClientsController',
        hasPermissions: ['CLIENTS:GET', 'CLIENTS:PUT', 'CLIENTS:DELETE']
    }).when('/clients/partenaire/:id', {
        templateUrl: 'app/clients/client.html',
        controller: 'ClientsController',
        hasPermissions: ['CLIENTS:GET']
    });
});
